import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Process from "../components/process"

import InfoWindow from "../components/infoWindow"
import { Link } from "gatsby"

//import InfoSection from ""

const more = [
  <div>
    <ul>
      <li> 24/7 Call-out in SEQ (contracted clients prioritised) </li>
      <li> Fast removal of blockage </li>
      <li> Professional analysis of damage &amp; cause </li>
      <li> Photos of cause (when possible)</li>
      <li> Photos of blockage (when requested) </li>
      <li> Drop test to confirm blockage removed </li>
    </ul>
    <p>
      Contact us to sign on to our Chute Cleaning serives for 2 free
      unblockages.
    </p>
  </div>,
  <div>
    <ul>
      <li> 2 FREE unblockages </li>
      <li> Priority unblockage</li>
      <li> Guaranteed 5 hour turnaround </li>
      <li> Regular Checkups </li>
    </ul>
    Contact us for more information.
    <p></p>
  </div>,
  <div>
    <ul>
      <li> Deoderiser package </li>
      <li> Personalised Service Report </li>
      <li> FREE Periodic Chute Updates </li>
      <li> 24/7 Chute Unblocking Call-outs </li>
      <li> Negotiable price </li>
      <li> 100% satisfaction guarantee </li>
      <li> and more... </li>
    </ul>
    Contact us to customise the service or recieve a demo service report.
    <p></p>
  </div>,
]

const InspectionReporting = (
  <div>
    <h2> On-call Chute Unblocking</h2>
    <i></i>
    <p>
      <h4>Safe and quick.</h4>
      Our on-call teams is equipped with inspection camera technology and the
      right tools to remove the toughest blockages any time you need. We clear
      the blockage safely and quickly without damaging your garbage chute.
      <br />
    </p>

    <p>
      <h4> Detailed service reports: </h4> Each unblocking service includes a
      detailed service report which diagnoses the cause of the blockage,
      indicates the floor of the blockage, and includes before and after photos.
      <br />
      <br />
      <h4> Blocked chute? </h4> Our 24/7 on-call staff will remove the toughest
      blockages any time you need
    </p>
  </div>
)
const RegularService = (
  <div>
    <h2> FREE Call-Outs</h2>
    <p>
      <h4> Two FREE unblockages. </h4> Signup to our{" "}
      <Link to="/chute-cleaning">exclusive regular cleaning service</Link> to
      recieve two free unblockages. <br />
      <a href="tel:0413231255">Call us now</a> for more information.
      <h4> Why? </h4> We offer two free unblockages to our regular cleaning
      clients as a promise that our cleaning services will reduce the risk of
      blockages. <br /> <br />
      <h4> Blocked chute? </h4> Our 24/7 on-call staff will remove the toughest
      blockages any time you need. <br />
    </p>
  </div>
)
const ExtraMile = (
  <div>
    <h2>We go the extra mile</h2>
    <p>
      <h4>Innovative: </h4>
      Our chute experts are always looking to improve our service quality. Ask
      about the innovative deoderizing packages we provide to help improve your
      residents' relationship with the chute.
      <h4> Competitive pricing: </h4> Our competitors are often over-priced.
      Call us to negotiate.
      <h4> Streamlined: </h4> Opt into our FREE inspection programme and never
      worry about your chute again. We do the hard work so you don't have to.
    </p>
  </div>
)

const ChuteUnblocking = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "247-unblocking-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "247-unblocking-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "chute-cleaning-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "chute-cleaning-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  console.log(data)
  return (
    <Layout style={{ height: "80vh" }}>
      <SEO
        title="24/7 Chute Unblocking South East Queensland"
        description="We offer round the clock access to on-call professionals to solve all your chute related problems in south east Queensland"
        lang="en"
        meta=""
      />
      <section id="top">
        <InfoWindow
          id="chute-cleaning"
          title={<>24/7 EMERGENCY UNBLOCKING</>}
          subtitle="Call us for immediate unblocking in Brisbane, Gold Coast, and the Sunshine Coast!."
          panes={[InspectionReporting, RegularService, ExtraMile]}
          buttons={["CONTACT ON-CALL STAFF", "BOOK SERVICE", "CUSTOM JOB"]}
          button_subtitles={[
            "After every service you receive a FREE personalised service report.",
            "Not sure what you need? Talk to an expert",
            "Request a custom job tailored to your needs",
          ]}
          images={[
            data.image1.childImageSharp.fluid,
            data.image2.childImageSharp.fluid,
            data.image3.childImageSharp.fluid,
          ]}
          more={more}
          expansion_title={[
            "What is included in the inspection report?",
            "What will I receive as a regular client?",
            "Does ChuteOff offer me any extras?",
          ]}
        />
      </section>

      <section className="container">
        <div className="row pd-30">
          <div className="col-lg-6">
            <h2>A Clean Chute Is Important</h2>
            <p>
              {" "}
              A dirty chute is an unhealthy chute, and if your trash chute
              smells, it needs professional attention. Every chute is different
              and our team of experts are waiting on-call to inspect and
              diagnose your chutes.
            </p>
            <p>A fresh chute is important because it: </p>
            <ul
              class="list-unstyled"
              style={{
                listStyleType: "disc",
                paddingLeft: "2em",
                paddingBottom: "1.8em",
              }}
            >
              <li>Keeps tenants happy and apartments full </li>
              <li>Minimises long-term maintenance costs </li>
              <li>Removes pest, bug and rodent problems </li>
              <li>Lengthens chute and chute door life </li>
              <li>Removes grease build-up and potential fire hazards </li>
              <li>Eliminates trash room odors </li>
              <li>Minimises risk of blockage </li>
              <li>Reduces chances of black mold </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <Img
              fluid={data.image4.childImageSharp.fluid}
              alt="Why a clean chute is important in Brisbane"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </section>
      <Process />
    </Layout>
  )
}

export default ChuteUnblocking
